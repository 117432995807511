import {
	AppstoreOutlined,
	BarChartOutlined,
	CloudOutlined,
	ShopOutlined,
	TeamOutlined,
	UploadOutlined,
	UserOutlined,
	VideoCameraOutlined,
} from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { Layout } from 'antd';
import classNames from 'classnames';
import { Suspense, createElement, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import '../../antCover.css';
import TRPCLayout from '../../utils/TRPCLayout';
import { useUpdateGlobalUserAgent, useUserAgent } from '../../utils/userAgent';
import LoadingUI from '../LoadingUI';
import Entry from './Entry';
import style from './layout.module.css';
import { useWoodAppManifest } from './useWoodManifest';
const { Header, Content, Footer, Sider } = Layout;

const items: MenuProps['items'] = [
	UserOutlined,
	VideoCameraOutlined,
	UploadOutlined,
	BarChartOutlined,
	CloudOutlined,
	AppstoreOutlined,
	TeamOutlined,
	ShopOutlined,
].map((icon, index) => ({
	key: String(index + 1),
	icon: createElement(icon),
	label: `nav ${index + 1}`,
}));

const loadingUI = <div className="m-container">loading</div>;
function WoodContainer() {
	useUpdateGlobalUserAgent();
	useWoodAppManifest();

	const [collapsed, setCollapsed] = useState(false);

	const { isMobile, isPC } = useUserAgent();
	const location = useLocation();

	const center = (
		<Suspense
			fallback={<LoadingUI />}
		>
			<Outlet />
		</Suspense>
	);

	if (
		location.pathname.includes('login') ||
		location.pathname.includes('print')
	) {
		return (
			<TRPCLayout app="wood" isUseAuth={false}>
				{center}
			</TRPCLayout>
		);
	}

	return (
		<TRPCLayout app="wood">
			<Layout hasSider={isPC}>
				<Entry setCollapsed={setCollapsed} collapsed={collapsed} />

				<Layout>
					<Content
						className={classNames({
							[style['main-layout'] as unknown as string]: isPC,
							[style.collapsed as unknown as string]: isPC && collapsed,
							[style['not-collapsed'] as unknown as string]:
								isPC && collapsed === false,
						})}
					>
						{center}
					</Content>

					{/* <WoodFooter /> */}
				</Layout>
			</Layout>
		</TRPCLayout>
	);
}

export default WoodContainer;
