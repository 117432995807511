import { Layout, Menu } from 'antd';
import { compact } from 'lodash-es';
import { useMatches, useNavigate } from 'react-router';
import { useUserAgent } from '../../utils/userAgent';
import { useGlobalManagementStore } from '../apartment/myInfo/useGlobalManagement';
import { useRequestHelp } from '../test/RequestHelp';
import styles from './layout.module.css';

const { Header, Sider } = Layout;

export default function Entry({
	collapsed,
	setCollapsed,
}: {
	collapsed: boolean;
	setCollapsed: React.Dispatch<React.SetStateAction<boolean>>;
}) {
	const { globalManagement, setGlobalManagement } = useGlobalManagementStore();
	const { isMobile, isPC } = useUserAgent();
	const navigate = useNavigate();

	const matches = useMatches();
	const diagnose = useRequestHelp();

	const MenuItems = (
		<>
			<Menu
				style={{ paddingBottom: '50px' }}
				defaultSelectedKeys={matches.map((i) => i.pathname)}
				mode={isPC ? 'inline' : 'horizontal'}
				// defaultOpenKeys={}
				items={compact([
					{
						key: '/wood/stock',
						label: '库存',
						onClick: () => {
							navigate('/wood/stock');
						},
					},
					globalManagement && {
						key: '/wood/costSetting',
						label: '成本设置',
						onClick: () => {
							navigate('/wood/costSetting');
						},
					},
					{
						key: '/wood/sell',
						label: '仓库出货',
						onClick: () => {
							navigate('/wood/sell');
						},
					},
					{
						key: '/wood/transaction',
						label: '送货单',
						onClick: () => {
							navigate('/wood/transaction');
						},
					},
					{
						key: '/wood/myInfo',
						label: '我的信息',
						onClick: () => {
							navigate('/wood/myInfo');
						},
					},
					globalManagement && {
						key: '/wood/StockStat',
						label: '库存统计',
						onClick: () => {
							navigate('/wood/StockStat');
						},
					},
					globalManagement && {
						key: '/wood/TransactionStat',
						label: '销售统计',
						onClick: () => {
							navigate('/wood/TransactionStat');
						},
					},
					globalManagement && {
						key: '/wood/myTeam',
						label: '团队管理',
						onClick: () => {
							navigate('/wood/myTeam');
						},
					},
					// {
					// 	key: '/wood/management',
					// 	label: '管理',
					// 	onClick: () => {},
					// },
					{
						key: '退出登陆',
						label: '退出登陆',
						onClick: () => {
							navigate(
								'/account/sign-out?url=https://i.laikezhan.com/wood/stock',
							);
						},
					},
				])}
			/>
		</>
	);

	if (isMobile) {
		return (
			<Header
				className={`${styles.noPrint} ${styles.header}`}
				style={isMobile ? { padding: 0 } : {}}
			>
				{MenuItems}
			</Header>
		);
	}

	return (
		<Sider
			onMouseEnter={() => {
				document
					.querySelector('.ant-layout-sider-children')
					?.classList.remove('overflow-hidden');
				document
					.querySelector('.ant-layout-sider-children')
					?.classList.add('overflow-y-auto');
			}}
			onMouseLeave={() => {
				document
					.querySelector('.ant-layout-sider-children')
					?.classList.add('overflow-hidden');
				document
					.querySelector('.ant-layout-sider-children')
					?.classList.remove('overflow-y-auto');
			}}
			collapsible
			collapsed={collapsed}
			onCollapse={(value) => setCollapsed(value)}
			theme={'light'}
			style={{
				overflow: 'auto',
				height: '100vh',
				position: 'fixed',
				left: 0,
				top: 0,
				bottom: 0,
			}}
		>
			{MenuItems}
		</Sider>
	);
}
